<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-15 10:29:01
 * @Message: Nothing
-->
<template>
    <div>
        <!-- header pages -->
        <div class="header-pages">
            <div class="pages-title">
                <div class="container">
                    <span>We Are Professional</span>
                    <h2>项目展示</h2>
                </div>
            </div>
        </div>
        <!-- end header pages -->
        <!-- our work -->
        <div class="our-work">
            <div class="container" v-viewer>
                <div class="row">
                    <div class="col-md-6 col-work">
                        <div class="entry">
                            <img src="../../assets/img/product_1.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6 col-work">
                        <div class="entry">
                            <img src="../../assets/img/product_2.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6 col-work">
                        <div class="entry">
                            <img src="../../assets/img/product_3.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6 col-work">
                        <div class="entry">
                            <img src="../../assets/img/product_4.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end our work -->

        <!-- counter -->
        <div class="counter section">
            <div class="container">
                <div class="content" data-aos="zoom-in" data-aos-duration="1500">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="entry">
                                <span>8</span>
                                涉及行业
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="entry">
                                <span>33</span>
                                已有项目
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="entry">
                                <span>21</span>
                                合作企业
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end counter -->
    </div>
</template>

<script>
export default {
    name: "product",
    mounted() {},
};
</script>

<style scoped></style>
